/* Login Page CSS Start Here */

.main {
  height: 100vh;
  width: 100%;

  overflow: hidden;
}

.section {
  display: flex;
  flex-direction: row;

  justify-content: center;
  /* padding: 40; */
  height: 100vh;
  width: 100%;
}
.column {
  /* position: absolute; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* padding: 50px; */

  width: 100%;
  /* border-radius: 10px; */
  border: black;
  border-color: black;
  /* margin: 15px; */
}
.loginRightContainer {
  max-width: 24rem;
  text-align: center;
}
.displayNone {
  display: none;
}
.title {
  font-size: large;
  font-weight: bold;
  color: black;
  margin-bottom: 10px;
}

.subtitle {
  color: #a0a0a0;
}

.lable {
  font-weight: bold;
  margin-top: 10px;
}
.fontBlackBold {
  font-weight: bold;
  color: black;
  font-size: 3rem;
  text-align: center;
}
.textBox {
  height: 40px;
  border-radius: 15px;
}
.imageSvg {
  width: 35rem;
  /* height: 200px; */
}
.imageSvgSmall {
  width: 8rem;
  /* height: 200px; */
}
.bgRed {
  background-color: red;
}
.rough {
  display: flex;
}
.customLabel label {
  width: 100%;
}
@media only screen and (min-width: 991px) {
  .displayNone {
    display: flex;
  }
}
/* Login Page CSS End Here */
