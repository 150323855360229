.main {
  height: 100vh;
  width: 100%;
  border: tomato solid;
  overflow: auto;
  margin: auto;
  display: flex;
  justify-content: center;
}
.container {
  width: 100%;
  height: 100%;
  margin: 20px;
  border: turquoise solid;
}
.category {
  width: 100%;
  padding: 5px;
}
.imageAncContent {
  display: flex;
  /* justify-content: flex-start; */
  flex-direction: row;
}
.table {
  width: 100%;
  height: 100%;
  padding: 5px;
}
.button {
  margin: 3px;
}
.image {
  height: 80px;
  width: 150px;
  border-radius: 8px;
}
