.main {
  height: 100vh;
  width: 100%;
}
.container {
  width: 100%;
  height: 100%;
  margin-top: 10px;
}
.paragraph {
  margin: 15px;
}

.mainTitle {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin: 15px;
}
