.main {
  height: 100vh;
  width: 100%;
  /* background-color: rgb(199, 234, 234); */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.section {
  background-color: rgb(48, 59, 214);
  height: 45%;
  width: 45%;
  margin: 5px;
  text-align: center;
  /* padding: 10px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  box-shadow: 5px 10px 8px #888888;
}

.section > div {
  background-color: rgb(180, 221, 15);
  height: 45%;
  width: 45%;
  margin: 5px;
  text-align: center;
  border-radius: 15px;
}
