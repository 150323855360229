.main {
  height: 100vh;
  width: 100%;
  overflow: auto;
  /* border: tomato solid; */
  margin: auto;
  display: flex;
  justify-content: center;
}
.container {
  width: 90%;
  height: 100%;
  margin: 20px;
  /* border: turquoise solid; */
  display: flex;
  flex-direction: column;
}

.form {
  padding: 20px;
  /* border: rgb(64, 93, 224) solid; */
  margin: 20px;
}
.button {
  margin-right: 6px;
}

.answerImage {
  width: 150px;
  height: 100px;
  border: solid;
  border-width: 1px;
  border-color: blue;
  border-radius: 7;
}
