.main {
  height: 100vh;
  width: 100%;
  border: tomato solid;
  overflow: auto;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.container {
  width: 100%;
  height: 100%;
  margin: 20px;
  border: turquoise solid;
}

.form {
  padding: 20px;
  border: rgb(64, 93, 224) solid;
  margin: 20px;
}

.image {
  height: 80px;
  width: 150px;
  border-radius: 8px;
}
.button {
  border-radius: 8px;
}
.headerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.headerText {
  font-size: large;
  color: white;
  /* text-align: center; */
  align-self: center;
  background-color: blueviolet;
  padding-left: 18px;
  padding-right: 18px;
  border-radius: 7px;
}
