.main {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: blue; */
}
.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  padding: 10px;
  /* background-color: brown; */
}
.button {
  margin-right: 1px;
}
.contentRight {
  justify-content: right;
  align-items: flex-end;
}
