.main {
  height: 100vh;
  width: 100%;
  overflow: auto;
  /* border: tomato solid; */
  margin: auto;
  display: flex;
  justify-content: center;
}
.container {
  width: 100%;
  height: 100%;
  margin: 20px;
  border: turquoise solid;
}
.heading {
  font-weight: 900;
  font-size: 15;
  color: #246ee9;
  letter-spacing: 1px;
}

.form {
  padding: 20px;
  /* border: rgb(64, 93, 224) solid; */
  margin: 20px;
}
.button {
  margin: 3px;
}
